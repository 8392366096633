<template>
  <v-container>
    <v-row justify="center">
      <v-col xs="12" xl="8">
        <!-- header text -->
        <v-row v-if="viewed_as === 'add'">
          <v-col>
            <h1>Create Payment</h1>

            <p class="grey--text text-subtitle-1">
              Adding a new payment is a breeze. Please fill the following input
              fields below. Once you're confident in the accuracy of the
              information, click the 'Submit' button and wait for the
              confirmation message.
            </p>
          </v-col>
        </v-row>

        <v-row v-if="viewed_as === 'edit'">
          <v-col>
            <h1>Updating Payment</h1>
          </v-col>
        </v-row>

        <v-row v-if="viewed_as === 'copy'">
          <v-col>
            <h1>Copy Payment</h1>
          </v-col>
        </v-row>

        <v-row v-if="viewed_as === 'invalid view'">
          <v-col>
            <h1>Invalid view type please try it again</h1>

            <v-btn @click="handle_cancel"> Cancel </v-btn>
          </v-col>
        </v-row>

        <!-- form -->
        <v-row v-if="viewed_as !== 'invalid view'">
          <v-col>
            <v-form v-model="form_is_valid" ref="form">
              <v-row>
                <!-- type -->
                <v-col>
                  <v-select
                    v-model="new_pymt_log.type"
                    :items="type_options"
                    label="Type"
                    hide-details="auto"
                    :rules="rule.required"
                  />
                </v-col>

                <!-- date -->
                <v-col>
                  <v-menu
                    v-model="date_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="new_pymt_log.date"
                        label="Date"
                        outlined
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                        :rules="rule.date"
                        hint="YYYY-MM-DD format"
                        append-icon="mdi-calendar-blank"
                      />
                    </template>
                    <v-date-picker
                      no-title
                      v-model="new_pymt_log.date"
                      @input="date_menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row>
                <!-- amount -->
                <v-col>
                  <v-text-field
                    v-model="new_pymt_log.amount"
                    label="Amount"
                    hide-details="auto"
                    @blur="
                      new_pymt_log.amount = formatCurrency(new_pymt_log.amount)
                    "
                    :rules="rule.required"
                    outlined
                  />
                </v-col>

                <v-col></v-col>
              </v-row>

              <v-row>
                <!-- notes -->
                <v-col>
                  <v-row v-if="new_pymt_log.notes.length > 0">
                    <v-col>
                      <p class="grey--text">Added Notes</p>

                      <v-data-table
                        class="bordered"
                        v-model="selected_notes"
                        :headers="[{ text: 'Note', value: 'note' }]"
                        :items="new_pymt_log.notes"
                        @click:row="update_note($event)"
                        item-key="order"
                        show-select
                        hide-default-footer
                      />

                      <v-btn
                        v-if="selected_notes.length > 0"
                        @click="delete_note"
                        class="red mt-2"
                      >
                        Delete
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-btn
                        v-if="!adding_note && !updating_note"
                        @click="adding_note = true"
                      >
                        Add Note
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row v-if="adding_note || updating_note">
                    <v-col>
                      <v-card class="bordered rounded-lg" flat>
                        <v-card-title>
                          <p class="text-h6">
                            {{ adding_note ? "Add" : "Update" }} Note
                          </p>
                        </v-card-title>

                        <v-card-text>
                          <v-textarea
                            v-model="new_note.note"
                            label="Notes"
                            hide-details="auto"
                            :rules="rule.required"
                            auto-grow
                            outlined
                          />
                        </v-card-text>

                        <v-divider />

                        <v-card-actions>
                          <v-btn
                            color="primary"
                            :disabled="!new_note.note"
                            @click="
                              adding_note ? save_note() : save_updated_note()
                            "
                            text
                          >
                            save
                          </v-btn>

                          <v-btn
                            @click="
                              adding_note = false;
                              updating_note = false;
                            "
                            text
                          >
                            cancel
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row v-if="viewed_as !== 'edit'">
                <!-- multiple or single -->
                <v-col cols="4">
                  <v-checkbox
                    v-model="multiple_entry"
                    label="Multiple Entry"
                    hide-details="auto"
                    @change="number_of_entries = 1"
                  />
                </v-col>

                <v-col v-if="multiple_entry" cols="5">
                  <v-text-field
                    v-model="number_of_entries"
                    label="Number of Entries"
                    type="number"
                    hide-details="auto"
                    :rules="rule.multiple"
                    outlined
                  />
                </v-col>
              </v-row>

              <!-- submit button -->
              <v-row>
                <v-col>
                  <v-btn @click="handle_submit" color="primary"> Submit </v-btn>

                  <v-btn class="ml-2" @click="handle_cancel"> Cancel </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon';

export default {
  props: ["viewed_as", "payment_log"],
  data: () => ({
    form_is_valid: false,

    multiple_entry: false,
    number_of_entries: 1,

    new_note: {
      note: null,
      createdAt: null,
      order: null,
    },
    adding_note: false,
    updating_note: false,
    selected_notes: [],

    date_menu: false,

    // payment log data
    new_pymt_log: {
      type: null,
      date: null,
      amount: null,
      notes: [],
    },

    // options
    type_options: ["Loan", "Payment"],

    // rules
    rule: {
      required: [(v) => !!v || "Required."],

      number: [
        (v) =>
          v === "" || v === null || /^\d+$/.test(v) || "Input must be a number",
      ],

      date: [
        (v) =>
          (v && /^\d{4}-\d{2}-\d{2}$/.test(v)) ||
          "Invalid date. Format should be YYYY-MM-DD",
      ],

      multiple: [
        (v) =>
          v === "" ||
          v === null ||
          !/^-?\d+$/.test(v) ||
          parseInt(v) > 0 ||
          "Input must be a number and greater than 0",
      ],
    },
  }),

  methods: {
    // add note
    save_note() {
      let new_order = 0;

      if (this.new_pymt_log.notes.length === 0) {
        new_order = new_order + 1;
      } else {
        // get the heighest order then add 1
        const highest_order = Math.max.apply(
          Math,
          this.new_pymt_log.notes.map((item) => item.order)
        );
        new_order = highest_order + 1;
      }

      this.new_note.order = new_order;
      this.new_note.createdAt = new Date().toISOString();

      this.new_pymt_log.notes.push(this.new_note);

      this.new_note = {
        note: null,
        createdAt: null,
        order: null,
      };
      this.adding_note = false;
    },

    update_note(event) {
      if (this.adding_note) {
        alert(
          "You are currently adding a note. Please finish adding the note before updating a note."
        );
        return;
      } else {
        this.updating_note = true;

        let note = this.new_pymt_log.notes.find((n) => n.order === event.order);

        this.new_note = note;

        console.log("editing note", this.new_note);
      }
    },

    save_updated_note() {
      const index = this.new_pymt_log.notes.findIndex(
        (n) => n.order === this.new_note.order
      );

      this.new_pymt_log.notes[index] = this.new_note;

      console.log("updated note", this.new_pymt_log.notes[index]);

      // Reset variables
      this.new_note = {
        note: null,
        createdAt: null,
        order: null,
      };

      this.updating_note = false;
    },

    // delete note
    delete_note() {
      if (window.confirm("Are you sure you want to delete this note?")) {
        this.selected_notes.forEach((note) => {
          const note_index = this.new_pymt_log.notes.findIndex(
            (n) => n.order === note.order
          );

          this.new_pymt_log.notes.splice(note_index, 1);
        });

        this.selected_notes = [];
      } else {
        console.log("cancelled");
        this.selected_notes = [];
      }
    },

    formatCurrency(amount) {
      let currencyValue = 0;

      if (amount) {
        amount = amount.toString();

        // Remove commas and dollar signs
        let unformattedValue = amount.replace(/[$,]/g, "");

        // Parse the input value as a float number
        currencyValue = parseFloat(unformattedValue) || 0;

        // Format the number with commas and a dollar sign
        let formatted_amount = currencyValue.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });

        return formatted_amount;
      } else {
        return 0;
      }
    },

    handle_submit() {
      const valid = this.$refs.form.validate();

      if (!valid) alert("Please fill in all required fields");

      if (valid) {
        switch (this.viewed_as) {
          case "add":
            this.$emit("submit_add", this.new_pymt_log, this.number_of_entries);
            break;
          case "edit": {
            this.$emit("submit_edit", this.new_pymt_log);
            break;
          } 
          case "copy":
            this.$emit("submit_add", this.new_pymt_log, this.number_of_entries);
            break;
        }
      }
    },

    handle_cancel() {
      this.$emit("close");
    },
  },

  mounted() {
    if (this.viewed_as === "edit") {
      this.new_pymt_log = this.payment_log;
    }

    if (this.viewed_as === "copy") {
      this.new_pymt_log = this.payment_log;
      this.new_pymt_log.notes = [];
    }

    if (this.viewed_as === "add") {
      // get the current date
      const today = new Date().toLocaleDateString('en-CA');
      
      this.new_pymt_log.date = today;
     
      console.log("new_pymt_log", this.new_pymt_log);
    }
  },
};
</script>

<style>
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
}
</style>
